<template>
  <YLayoutBodyMenu :menu="[
      {title:'商品管理',path:'/shop/goods/manage'},
      {title:'分类管理',path:'/shop/goods/category'},
      {title:'专题管理',path:'/shop/goods/theme'},
      {title:'运费模版',path:'/shop/goods/shipping'},
      {title:'快速导入',path:'/shop/goods/import'},
  ]">
    <router-view/>
  </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";

export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>