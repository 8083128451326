var render = function render(){var _vm=this,_c=_vm._self._c;return _c('YLayoutBodyMenu',{attrs:{"menu":[
    {title:'商品管理',path:'/shop/goods/manage'},
    {title:'分类管理',path:'/shop/goods/category'},
    {title:'专题管理',path:'/shop/goods/theme'},
    {title:'运费模版',path:'/shop/goods/shipping'},
    {title:'快速导入',path:'/shop/goods/import'},
]}},[_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }