<template>
  <el-menu unique-opened v-if="menu.length > 0" router :default-active="$route.path" style="height: calc(100% - .3rem);margin-top: .3rem">
    <template v-for="(item,key) in menu">
      <el-menu-item v-if="!item.children" :key="key" :index="item.path" >{{ item.title }}</el-menu-item>
      <el-submenu style="width: 100%" v-else :index="item.path" :key="key">
        <template slot="title">
          <span>{{ item.title }}</span>
        </template>
        <el-menu-item style="width: 100%;min-width: unset" v-for="(v,k) in item.children" :key="k" :index="v.path" >{{ v.title }}</el-menu-item>
      </el-submenu>
    </template>

  </el-menu>
</template>

<script>
export default {
  name: "YSimpleMenu",
  props:{
    menu:{
      type:Array,
      default(){
        return[]
      },
    }
  }
}
</script>

<style scoped>

</style>