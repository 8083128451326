<template>
  <el-scrollbar style="height: calc(100vh - 3.5rem);">
    <div style="padding: 20px">
      <slot></slot>
    </div>
  </el-scrollbar>
</template>

<script>
export default {
  name: "YMainBody"
}
</script>

<style scoped>

</style>